import React from "react"
import {Button, Card, ListSubheader, Typography, Avatar, AppBar, Toolbar, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import CustomScroller from 'react-custom-scroller';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import PropTypes from "prop-types"
class BotPanel extends React.Component {
  constructor(){
    super()
    this.state = {'guilds': [], "mutual_guilds": [],"mutual_guilds_loaded": false, "current_guild_data": null}
  }
  set_state_property = (property, value)=>{
    var currentstate = this.state
    currentstate[property] = value
    this.setState(currentstate)
  }
  batch_set_state = (state_object)=>{
      var currentstate = this.state
      Object.entries(state_object).forEach(element=>{
          currentstate[element[0]] = element[1]
      })
      this.setState(currentstate)
  }
  switch_guild = async (guild_id)=>{
    window.history.pushState({guild_id: guild_id}, this.state.guilds.filter(guild=>guild_id==guild.id)[0].name, `/discordbot/panel/guilds/${guild_id}`);
    var response = await fetch(`/discordbot/panel/guilds/${guild_id}/json`)
    var data = await response.json()
    this.set_state_property('current_guild_data', data)
  }
  render() {
    var user = this.props.user
    var avatar_url = `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`

    var guild_list = []
    this.state.guilds.forEach(guild=>{
      var is_guild_mutual = this.state.mutual_guilds.includes(guild.id)
      const guild_avatar = `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`
      var button
      if(is_guild_mutual){
        button = <Button onClick={event=>{this.switch_guild(guild.id)}}>Manage Server</Button>
      }else{
        button = <Button onClick={event=>{window.open(`https://discord.com/oauth2/authorize?client_id=468773589647097857&scope=bot%20applications.commands&permissions=8&guild_id=${guild.id}`, '_blank')}}>Add to Server</Button>
      }
      var selected = false
      if(this.state.current_guild_data!=null){
        if(guild.id == this.state.current_guild_data.id){
          selected = true
        }
      }
      var component = (
        <ListItem key={guild.id} style={{"width": "400px"}} selected={selected}>
          <ListItemIcon><Avatar src={guild_avatar} alt={guild.name} /></ListItemIcon>
          <ListItemText primary={guild.name}></ListItemText>
            {button}
        </ListItem>
      )
      guild_list.push(component)
    })

    return (
      <React.Fragment>
        <AppBar color="secondary">
            <Toolbar>
                <div style={{"flexGrow": 1}} />
                <Button color="inherit">
                    <Avatar src={avatar_url} alt={user.username||"Loading..."}/>
                    {`${user.username}#${user.discriminator}`||"Loading..."}
                    <ArrowDropDownIcon />
                </Button>
            </Toolbar>
        </AppBar>
        <Toolbar />
        <CustomScroller style={{"height": "90%", "width": 400, "position": "fixed"}}>
          <List>
              <ListSubheader>{`Your Servers`}</ListSubheader>
              {guild_list}
          </List>
        </CustomScroller>
      </React.Fragment>
    );
  }
  messagebus_callback = (data)=>{
    var payload = JSON.parse(data)
    console.log(`CHANNEL[User-${this.props.user.id}]:`, payload)
    if(payload.t=="MUTUAL_GUILD_LIST_UPDATE"){
      this.batch_set_state({"mutual_guilds": payload.d, "mutual_guilds_loaded": true})
    }
  }
  async componentDidMount(){
    this.set_state_property("guilds", this.props.guilds)
    while(MessageBus==undefined){
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    MessageBus.start()
    MessageBus.callbackInterval = 500;
    MessageBus.subscribe(`/db_users/${this.props.user.id}`, this.messagebus_callback);

    
    if(this.props.selected_guild_payload!=undefined){
      this.set_state_property("current_guild_data", this.props.selected_guild_payload)
    }
    while(this.state.mutual_guilds_loaded==false){
      Rails.ajax({url: "/discordbot/request_mutual_guilds",type: "POST"})
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
}

export default BotPanel
