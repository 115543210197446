import React from "react"
import {Button, Card, ListSubheader, Typography, Avatar, AppBar, Toolbar, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import CustomScroller from 'react-custom-scroller';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
class GuildPanel extends React.Component {
    constructor(){
      super()
      this.state = {'guilds': [], "mutual_guilds": []}
    }
    set_state_property = (property, value)=>{
        var currentstate = this.state
        currentstate[property] = value
        this.setState(currentstate)
    }
    batch_set_state = (state_object)=>{
        var currentstate = this.state
        Object.entries(state_object).forEach(element=>{
            currentstate[element[0]] = element[1]
        })
        this.setState(currentstate)
    }
    render() {
        return(
            <CustomScroller>
                
            </CustomScroller>
        )
    }
}
export default GuildPanel